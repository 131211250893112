/*! 2025-2-14 17:56:46 */

.inlineFlex[data-v-08bab946] {
  display: inline-flex;
}

[data-v-07fbaa3e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-07fbaa3e]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-07fbaa3e]:hover{cursor:pointer}.green-box[data-v-07fbaa3e]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-07fbaa3e]{background:rgba(245,154,35,0.2);color:#f59a23}

[data-v-2e653972]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.custom-container[data-v-2e653972]{padding:0 20px 20px 20px}.audit-wrapper[data-v-2e653972]{padding:0 20px 20px 20px !important}[data-v-2e653972] .el-table__footer-wrapper .has-gutter{height:40px;font-family:'PingFang Bold'}[data-v-2e653972] .el-table__footer-wrapper .has-gutter .required-th::before{display:none}[data-v-2e653972] .el-table__footer-wrapper .has-gutter td{text-align:center}.mr5[data-v-2e653972]{margin-right:5px}


/*# sourceMappingURL=chunk-4c74f7d1.ec9e5fc3.css.map*/